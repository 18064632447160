import React, { useEffect, useState } from 'react';
import './Companies.css';
import CreateCompany from './CreateCompany';
import ViewCompany from './ViewCompany/ViewCompany';
import { useNavigate } from 'react-router-dom';
import FilterAPIs from '../../../MasonyFixingsAPIs/FilterAPIs/FilterAPIs';
import {Form, Row, Col, Table} from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';


export default function Companies(){

    const ColumnHeaders = [
        {name: 'Name'},
        // {name: 'Delete'},
        {name: 'View'},
    ]
    const [addNew, setAddNew] = useState(false);
    const navigate = useNavigate();
       
    const [searchString, setSearchString] = useState('');
    const [CompaniesData, setCompaniesData] =  useState([]);
    const [selectedCompany, setSelectedCompany] =  useState();
    const [showCompany, setShowCompany] =  useState(false);

    const getAllCompanies = (string) => {

        const postJSON = {
            name: string
        }
        FilterAPIs.PostCompanyFilter(postJSON)
        .then((response) => {
            setCompaniesData(response.data);
        }) 
        .catch((err) => {
            console.log(err)
            if(err.response.status === 401){
                if(err.response.data.logout){
                    navigate('/login');
                }
                else{
                    window.alert(err.response.data.message)
                }
            }
        })
    }

    const handleAddNew = () => {
        
        setAddNew(false);        
        setSearchString('');
        getAllCompanies('');
    }

    const handleCancel = () => {
        setAddNew(false);
    }

    const handleSelect = (id) => {
        setSelectedCompany(id);
        setShowCompany(true);
    }

    const handleClose = () => {
        setShowCompany(false);
        setSelectedCompany();
    }

    useEffect(() => {
        getAllCompanies(searchString);
    }, [searchString])


    const ThData = () => {
        return ColumnHeaders.map((header, index) => {
            return(
                <th key={index}>{header.name}</th>
            )
        })
    }

    const tdData = () => {
        return CompaniesData.map((company) => {
            return(
                <tr key={company.id}>
                    <td>{company.name}</td>
                    <td><button onClick={() => handleSelect(company.id)}><AiFillEdit/></button></td>
                </tr>
            )
        })
    }
    
    return(
        <div className='company-display'>

            <h1>Companies</h1>
            <hr/>

            {addNew ?
                <div>
                    <CreateCompany handleCancel={handleCancel} handleAddNew={handleAddNew} /> 
                </div>
            :
            
            <div>
                {(selectedCompany && showCompany) ?
                    <>
                        <button onClick={() => handleClose()}>Back</button>
                        <ViewCompany  getCompanies={getAllCompanies} companyID={selectedCompany} handleClose={handleClose}/>
                    </>
                    :
                    <>
                        <button onClick={() => setAddNew(true)}>Add New</button>

                        <Row className="mb-2">
                            <Col sm={6}>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={4}>Search Company: </Form.Label>
                                    <Col>
                                        <Form.Control 
                                            value={searchString} 
                                            onChange={(e) => setSearchString(e.target.value)} 
                                        />
                                    </Col>
                                </Form.Group>   
                            </Col>
                            <Col>
                                <h5>Showing: {CompaniesData.length}</h5>
                            </Col>
                        </Row>
                        <Table size='sm' striped hover responsive>
                            <thead>
                                <tr>
                                    {ThData()}
                                </tr>
                            </thead>
                            <tbody>
                                {tdData()}
                            </tbody>
                        </Table>
                    </>
                    
                }
            </div>
            }
        </div>
    )
}
